import * as i0 from '@angular/core';
import { Injectable } from '@angular/core';
import * as i1$1 from '@angular/router';
import { BehaviorSubject, of, firstValueFrom, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import * as i1 from '@angular/common/http';
class AtlantisNgxAuthService {
  constructor(http) {
    this.http = http;
    // Usefull behavior to know if the user is logged in or not --> can be used
    this.identityLoaded$ = new BehaviorSubject(false);
    this.identity$ = new BehaviorSubject(undefined);
    this.identity = null;
    this.url = 'https://api.dotocean.eu/atlantis';
  }
  async login(username, password) {
    return await this.loginByHeader(this.getHeaderString(btoa('user=' + username + ',pass=' + password)));
  }
  getHeaderString(btoa) {
    return 'ATLANTIS ' + btoa;
  }
  IsAdmin() {
    return this.identity?.Application?.Role?.IsAdmin ?? false;
  }
  HasApplicationAccess(applicationName) {
    return this.identity?.Session?.ApplicationConfigs?.some(e => e.Application.Name.toLowerCase() == applicationName.toLowerCase()) ?? false;
  }
  async loginByToken(token) {
    return await this.loginByHeader(this.getHeaderString(btoa('token=' + token)));
  }
  async loginBySession(session) {
    return await this.loginByHeader(this.getSessionHeader(session));
  }
  getSessionHeader(session) {
    return this.getHeaderString(btoa('session=' + session));
  }
  logout(closeOnServer = true) {
    if (this.identity == null) return of();
    const sessionToken = this.identity.Session.SessionToken;
    localStorage.setItem('Session', '');
    this.identity = null;
    this.identity$.next(undefined);
    if (closeOnServer) {
      return this.http.get(this.url + '/session/close', {
        headers: {
          Authorization: this.getSessionHeader(sessionToken)
        }
      });
    }
    return of();
  }
  async loginByHeader(authHeader) {
    try {
      this.identityLoaded$.next(false);
      var identity = await firstValueFrom(this.http.get(this.url + '/session', {
        headers: {
          Authorization: authHeader
        }
      }));
      if (identity != null) {
        localStorage.setItem('Session', identity.Session.SessionToken);
        this.identity = identity;
        this.identity$.next(identity);
      }
      return identity;
    } catch (error) {
      this.identity$.next(undefined);
      this.identity = null;
      return null;
    } finally {
      this.identityLoaded$.next(true);
    }
  }
  static {
    this.ɵfac = function AtlantisNgxAuthService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AtlantisNgxAuthService)(i0.ɵɵinject(i1.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AtlantisNgxAuthService,
      factory: AtlantisNgxAuthService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AtlantisNgxAuthService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class AtlantisNgxAuthInterceptor {
  constructor(auth) {
    this.auth = auth;
  }
  intercept(request, next) {
    if (this.auth.identity != null) {
      request = request.clone({
        setHeaders: {
          Authorization: this.auth.getSessionHeader(this.auth.identity.Session.SessionToken)
        }
      });
    }
    return next.handle(request).pipe(map(event => {
      return event;
    }), catchError(error => {
      console.log(error);
      if (this.auth.identity != null && error.status == 401) this.auth.logout(false);
      return throwError(() => error);
    }));
  }
  static {
    this.ɵfac = function AtlantisNgxAuthInterceptor_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AtlantisNgxAuthInterceptor)(i0.ɵɵinject(AtlantisNgxAuthService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AtlantisNgxAuthInterceptor,
      factory: AtlantisNgxAuthInterceptor.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AtlantisNgxAuthInterceptor, [{
    type: Injectable
  }], () => [{
    type: AtlantisNgxAuthService
  }], null);
})();
class AtlantisNgxAuthGuard {
  constructor(router, authService) {
    this.router = router;
    this.authService = authService;
  }
  async canActivate(route, state) {
    if (this.authService.identity == null) {
      var session = route.queryParams['session'];
      if (!session) session = localStorage.getItem('Session');
      if (session) {
        try {
          let identity = await this.authService.loginBySession(session);
          if (identity) console.info(`Authenication session is still valid for user ${identity?.Session.User.UserName}`);else console.warn('Authenication session is expired!');
        } catch (error) {
          this.authService.identity = null;
          console.warn('Authenication session is expired!');
        }
      }
      var token = route.queryParams['token'];
      if (token) {
        try {
          let identity = await this.authService.loginByToken(token);
          if (identity) console.info(`Authenication token valid for company ${identity?.Session.Company.Name}`);else console.warn('Authenication session is expired!');
        } catch (error) {
          this.authService.identity = null;
          console.warn('Authenication token is expired!');
        }
      }
      if (!this.authService.identity) {
        await this.router.navigate(['/login'], {
          queryParams: {
            returnUrl: state.url
          }
        });
        return false;
      }
      return true;
    } else {
      return true;
    }
  }
  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
  static {
    this.ɵfac = function AtlantisNgxAuthGuard_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AtlantisNgxAuthGuard)(i0.ɵɵinject(i1$1.Router), i0.ɵɵinject(AtlantisNgxAuthService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AtlantisNgxAuthGuard,
      factory: AtlantisNgxAuthGuard.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AtlantisNgxAuthGuard, [{
    type: Injectable
  }], () => [{
    type: i1$1.Router
  }, {
    type: AtlantisNgxAuthService
  }], null);
})();
class Identity {}
class Session {}
class User {}
class Company {}
class ApplicationConfig {}
class Application {}
class Role {}

/*
 * Public API Surface of atlantis-ngx-auth
 */

/**
 * Generated bundle index. Do not edit.
 */

export { Application, ApplicationConfig, AtlantisNgxAuthGuard, AtlantisNgxAuthInterceptor, AtlantisNgxAuthService, Company, Identity, Role, Session, User };
