import { Component, Input } from '@angular/core';
import { ThemedImageDirective } from '../../../directives/image-theme.directive';

@Component({
  selector: 'app-loading-dot',
  standalone: true,
  imports: [ThemedImageDirective],
  templateUrl: './loading-dot.component.html',
  styleUrl: './loading-dot.component.scss'
})
export class LoadingDotComponent {
@Input()
public isSelected = false;

@Input()
public themeReverseOnlyLightMode = false;
}
