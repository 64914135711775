import { Component, effect, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FleetManagerComponents } from '../../../../shared/fleetmanager-components.module';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { AisReplayService } from '../../../../services/ais-replay.service';
import TrackSymbol from '@arl/leaflet-tracksymbol2';
import { GetObjectHistoryResponse } from '@dotocean/virtualworld-ngx-services';
import moment from 'moment';
import { GetRectangleLatLng, GetRectanglePolygon } from '../../../../helpers/polygon.helper';
import { MapTrackerData } from '../../../../models/map-tracker-data';
import { VesselToFollow } from '../vessel-tofollow.class';
import { MapService } from '../../../../services/map.service';
import { CircleMarker, LatLngExpression } from 'leaflet';
import L from 'leaflet';
import { MapMarkerService } from '../../../../services/map-marker.service';

export function getMaxSpeed(histories: GetObjectHistoryResponse[]): number | undefined {
  return histories.reduce((max: number | undefined, history: GetObjectHistoryResponse) => {
    if (history.s !== undefined) {
      return max === undefined || history.s > max ? history.s : max;
    }
    return max;
  }, undefined);
}

@Component({
  selector: 'app-events-detail-player',
  standalone: true,
  imports: [FleetManagerComponents, NgbDropdownModule],
  templateUrl: './events-detail-player.component.html',
  styleUrl: './events-detail-player.component.scss',
})
export class EventsDetailPlayerComponent implements OnInit, OnDestroy {
  @Input() public direction: 'horizontal' | 'vertical' = 'vertical';

  @Input() public showSlider = true;

  private readonly mapService = inject(MapService);
  private readonly mapMarkerService = inject(MapMarkerService);
  public readonly aisReplayService = inject(AisReplayService);

  private vesselMarkers: Record<number, TrackSymbol | CircleMarker> = {};
  private vesselRect: Record<number, L.Polygon> = {};

  private vesselNearbyMarkers: Record<number, TrackSymbol | CircleMarker> = {};
  private vesselNearbyRect: Record<number, L.Polygon> = {};

  private readonly markerGroup: L.LayerGroup = L.layerGroup();

  private firstViewSet = true;

  public inifinty = Infinity;
  public nearbyIconHovering = false;

  public constructor() {
    effect(() => {
      if (!this.mapService.mapLoaded()) return;
      // Initialize the custom pane after the map is created
      this.mapService.addPane('vesselTrackerPane', '401');

      this.markerGroup.addTo(this.mapService.Map!);
    });

    effect(() => {
      if (!this.mapService.mapLoaded()) return;
      const vessels = this.aisReplayService.vessels();
      if (vessels.length === 0) return;

      this.drawTracker(vessels);
    });

    //Effect to update when the vessels, currentTime and Other settings change
    effect(() => {
      const vessels = this.aisReplayService.vessels();

      if (vessels.length === 0) return;
      const currentTime = this.aisReplayService.currentTime();
      const enableSmoothPlayback = this.aisReplayService.settings.enableSmoothPlayback();
      const followVessel = this.aisReplayService.settings.followVessel();
      const showTooltipTarget = this.aisReplayService.settings.showTooltipTarget();
      const showTooltipSource = this.aisReplayService.settings.showTooltipSource();
      vessels.forEach((vessel) => {
        this.updateVesselPosition(vessel, currentTime, enableSmoothPlayback, followVessel, showTooltipTarget, showTooltipSource);
      });
    });

    effect(() => {
      const vesselsNearby = this.aisReplayService.vesselsNearby();

      if (vesselsNearby.length === 0) return;
      const currentTime = this.aisReplayService.currentTime();
      const enableSmoothPlayback = this.aisReplayService.settings.enableSmoothPlayback();
      const showNearbyVessels = this.aisReplayService.settings.showNearbyVessels();

      if (!showNearbyVessels) {
        this.clearNearbyVesselMarkers();

        return;
      }
      vesselsNearby.forEach((vessel) => {
        this.updateVesselPosition(vessel, currentTime, enableSmoothPlayback, false, false, false, true);
      });
    });
  }
  public ngOnDestroy(): void {
    this.clearMarkers();
    this.mapService.Map?.removeLayer(this.markerGroup);
  }

  private clearMarkers() {
    Object.values(this.vesselMarkers).forEach((marker) => this.markerGroup.removeLayer(marker));
    this.vesselMarkers = {};

    Object.values(this.vesselRect).forEach((marker) => this.markerGroup.removeLayer(marker));
    this.vesselRect = {};
    this.clearNearbyVesselMarkers();
  }

  private clearNearbyVesselMarkers() {
    Object.values(this.vesselNearbyMarkers).forEach((marker) => this.markerGroup.removeLayer(marker));
    this.vesselNearbyMarkers = {};

    Object.values(this.vesselNearbyRect).forEach((marker) => this.markerGroup.removeLayer(marker));
    this.vesselNearbyRect = {};
  }

  public async ngOnInit() {
    this.aisReplayService.replayStopped.subscribe(() => {
      this.clearMarkers();

      this.aisReplayService.vessels().forEach((vessel) => {
        this.updateVesselPosition(
          vessel,
          this.aisReplayService.currentTime(),
          this.aisReplayService.settings.enableSmoothPlayback(),
          this.aisReplayService.settings.followVessel(),
          this.aisReplayService.settings.showTooltipTarget(),
          this.aisReplayService.settings.showTooltipSource()
        );
      });
    });
  }

  private drawTracker(vessels: VesselToFollow[]) {
    vessels
      .filter((v) => v.isTarget)
      .forEach((v) => {
        const pointsWithSpeed = v.histories.sort((a, b) => a.time!.getTime() - b.time!.getTime());

        const maxSpeed = getMaxSpeed(v.histories) ?? 10;
        // Function to map speed to color
        const getColorFromSpeed = (speed: number) => {
          // Assuming speed ranges from 0 to 9
          const red = Math.min(255, Math.floor((1 - speed / maxSpeed) * 255));
          const green = Math.min(255, Math.floor((speed / maxSpeed) * 255));
          return `rgb(${red},${green},0)`;
        };

        // Remove existing polylines if any
        if (v.vesselTrackerSegments.length > 0) {
          v.vesselTrackerSegments.forEach((segment) => this.markerGroup.removeLayer(segment));
        }
        v.vesselTrackerSegments = [];

        // Create segments with colors
        for (let i = 0; i < pointsWithSpeed.length - 1; i++) {
          const point1 = pointsWithSpeed[i];
          const point2 = pointsWithSpeed[i + 1];
          const latlngs = [[point1.lat!, point1.lng!] as LatLngExpression, [point2.lat!, point2.lng!] as LatLngExpression];
          const speed = ((point1.s ?? 0) + (point2.s ?? 0)) / 2; // Average speed
          const color = getColorFromSpeed(speed);

          const segment = L.polyline(latlngs, {
            color: color,
            weight: 3,
            opacity: 0.8,
            pane: 'vesselTrackerPane',
          }).addTo(this.markerGroup);

          v.vesselTrackerSegments.push(segment);
        }
      });
  }

  //For each vessel, update the marker's position based on the current playback time.
  private updateVesselPosition(
    vessel: VesselToFollow,
    currentTime: number,
    enableSmoothPlayback: boolean,
    followVessel: boolean,
    showTooltipTarget: boolean,
    showTooltipSource: boolean,
    isNearbyVessel = false
  ) {
    const marker = isNearbyVessel ? this.vesselNearbyMarkers[vessel.vesselInfo.mmsi!] : this.vesselMarkers[vessel.vesselInfo.mmsi!];
    const vesselEnd30Min = moment.utc(vessel.end).add(30, 'minutes').toDate();
    if (vessel.end && vesselEnd30Min.getTime() <= currentTime) {
      if (marker) {
        this.markerGroup.removeLayer(marker);
        const rectMarker = isNearbyVessel ? this.vesselNearbyRect[vessel.vesselInfo.mmsi!] : this.vesselRect[vessel.vesselInfo.mmsi!];
        if (rectMarker) this.markerGroup.removeLayer(rectMarker);
        if (isNearbyVessel) {
          delete this.vesselNearbyMarkers[vessel.vesselInfo.mmsi!];
          delete this.vesselNearbyRect[vessel.vesselInfo.mmsi!];
        } else {
          delete this.vesselMarkers[vessel.vesselInfo.mmsi!];
          delete this.vesselRect[vessel.vesselInfo.mmsi!];
        }
      }
      return;
    }

    const data = vessel.histories;

    // Find the two data points surrounding currentTime
    const index = data.findIndex((point) => new Date(point.time!).getTime() > currentTime);
    if (index === -1 || index === 0) {
      // No data to display at this time
      return;
    }

    const point1 = data[index - 1];
    const point2 = data[index];

    const position = enableSmoothPlayback ? this.interpolatePosition(point1, point2, currentTime) : point2;

    if ((vessel.isTarget && followVessel) || this.firstViewSet) {
      setTimeout(
        () => {
          this.mapService.Map?.setView([position.lat!, position.lng!], this.mapService.Map.getZoom());
          this.firstViewSet = false;
        },
        this.firstViewSet ? 500 : 0
      );
    }

    this.drawObjectRealDimensions(vessel, position.lat!, position.lng!, point2, isNearbyVessel);

    const trackerData = new MapTrackerData(
      vessel.vesselInfo.name,
      vessel.vesselInfo.mmsi!,
      vessel.vesselInfo.meta?.imoNumber,
      vessel.vesselInfo.meta?.eniNumber,
      position.lat!,
      position.lng!,
      point2.c!,
      point2.s,
      point2.t_h,
      undefined,
      moment.utc(point2.time).local(),
      vessel.color,
      undefined,
      undefined,
      undefined
    );

    this.mapMarkerService.addTrackerSymbol(
      marker,
      trackerData,
      this.markerGroup,
      isNearbyVessel
        ? (ts: TrackSymbol | CircleMarker) => {
            this.vesselNearbyMarkers[vessel.vesselInfo.mmsi!] = ts;
          }
        : (ts: TrackSymbol | CircleMarker) => {
            this.vesselMarkers[vessel.vesselInfo.mmsi!] = ts;
          },
      vessel.isTarget ? showTooltipTarget : showTooltipSource,
      isNearbyVessel
    );
  }

  //This is used to provide smooth movement between data points, interpolate positions based on time.
  private interpolatePosition(point1: GetObjectHistoryResponse, point2: GetObjectHistoryResponse, currentTime: number) {
    const time1 = new Date(point1.time!).getTime();
    const time2 = new Date(point2.time!).getTime();
    const ratio = (currentTime - time1) / (time2 - time1);

    const lat = point1.lat! + ratio * (point2.lat! - point1.lat!);
    const lng = point1.lng! + ratio * (point2.lng! - point1.lng!);

    return { lat, lng };
  }

  private drawObjectRealDimensions(
    vessel: VesselToFollow,
    latitude: number,
    longitude: number,
    currentPoint: GetObjectHistoryResponse,
    isNearbyVessel: boolean
  ) {
    if (!vessel.vesselInfo.width || !vessel.vesselInfo.length || !this.mapService.Map) return;

    const vesselrect = isNearbyVessel ? this.vesselNearbyRect[vessel.vesselInfo.mmsi!] : this.vesselRect[vessel.vesselInfo.mmsi!];
    const heading = currentPoint.c ?? 0;
    const width =
      !vessel.vesselInfo.meta?.dimToPort || !vessel.vesselInfo.meta?.dimToStarboard
        ? vessel.vesselInfo.width
        : vessel.vesselInfo.meta?.dimToPort + vessel.vesselInfo.meta?.dimToStarboard;
    const length =
      !vessel.vesselInfo.meta?.dimToBow || !vessel.vesselInfo.meta?.dimToStern
        ? vessel.vesselInfo.length
        : vessel.vesselInfo.meta?.dimToBow + vessel.vesselInfo.meta?.dimToStern;

    if (vesselrect) {
      const latlngProjected = GetRectangleLatLng(latitude, longitude, heading, width, length);
      vesselrect.setLatLngs(latlngProjected);
      return;
    }

    const rect = GetRectanglePolygon(
      latitude,
      longitude,
      vessel.color,
      heading,
      width,
      length,
      isNearbyVessel ? 'vesselNearbyPane' : 'vesselPane',
      isNearbyVessel
    ).addTo(this.markerGroup);

    if (isNearbyVessel) this.vesselNearbyRect[vessel.vesselInfo.mmsi!] = rect;
    else this.vesselRect[vessel.vesselInfo.mmsi!] = rect;
  }
}
