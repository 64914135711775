<div class="loading-popup">

  <app-loading-dot class="loading-popup--icon"></app-loading-dot>
  <div class="loading-popup--content">
    @if(content){
      <h4>{{ content }}</h4>
    }
    <ng-content></ng-content>
  </div>
</div>
