import { CommonModule } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-button-group',
  standalone: true,
  imports: [CommonModule],
  encapsulation: ViewEncapsulation.None, //Important for the * modifier in css to work!
  templateUrl: './button-group.component.html',
  styleUrl: './button-group.component.scss'
})
export class ButtonGroupComponent {
  @Input() public direction: 'horizontal' | 'vertical' = 'vertical';

}
