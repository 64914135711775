<div class="loading-dot">
  <img
    class="loading-dot--dot"
    themedImage
    [themeReverse]="isSelected"
    srcLight="images/dotO-dark.png"
    srcDark="images/dotO-light.png"
    alt="dotOcean"
  />

  <img
    class="loading-dot--img"
    [themeReverse]="isSelected"
    [themeReverseOnlyLightMode]="themeReverseOnlyLightMode"
    themedImage
    srcLight="images/dotO-nodot-dark.png"
    srcDark="images/dotO-nodot-light.png"
    alt="dotOcean"
  />
</div>
