import { effect, EventEmitter, inject, Injectable, signal, WritableSignal } from '@angular/core';
import { VesselToFollow } from '../app/events/events-detail/vessel-tofollow.class';
import { LatLng } from 'leaflet';
import * as turf from '@turf/turf';
import { AggregateLevel, GetObjectHistoryResponse, HistoryClient } from '@dotocean/virtualworld-ngx-services';
import { catchError, finalize, map, of, tap } from 'rxjs';
import { VesselClient, VesselInfo, VesselMeta } from './apis/cloud.service';

export class AisReplaySettings {
  public readonly settingsKey = 'ais-replay.settings';

  public speedMultiplier = signal(1); // Default speed is 1x
  public enableSmoothPlayback = signal(true);
  public followVessel = signal(false);
  public showTooltipSource = signal(true);
  public showTooltipTarget = signal(true);
  public showNearbyVessels = signal(true);

  public readonly speedMultiplierKey = `${this.settingsKey}.speedMultiplier`;
  public readonly enableSmoothPlaybackKey = `${this.settingsKey}.enableSmoothPlayback`;
  public readonly followVesselKey = `${this.settingsKey}.followVessel`;
  public readonly showTooltipSourceKey = `${this.settingsKey}.showTooltip.source`;
  public readonly showTooltipTargetKey = `${this.settingsKey}.showTooltip.target`;
  public readonly showNearbyVesselsKey = `${this.settingsKey}.showNearbyVessels`;
}

@Injectable()
export class AisReplayService {
  private readonly historyClient = inject(HistoryClient);
  private readonly vesselClient = inject(VesselClient);

  //implements OnDestroy {
  private readonly timeIncrement = 1000; // 1 second increment
  private readonly playbackSpeed = 100; // Milliseconds between intervals

  public minTime: number;
  public maxTime: number;

  public readonly speedMultiplierProfiles = [1, 5, 10, 20, 100];

  //TODO: This will need to be saved the the localstorage when any of the values change
  public settings: AisReplaySettings = new AisReplaySettings(); // Default speed is 1x

  public isFromEvent = false;

  public currentTime = signal(0);
  public isPlaying = signal(false);
  public isLoadingSecondaryVessels = signal(true);

  public vessels: WritableSignal<VesselToFollow[]> = signal([]);
  public vesselsNearby: WritableSignal<VesselToFollow[]> = signal([]); //These are the vessels AROUND the history replay vessels

  public replayStarted = new EventEmitter();
  public replayPaused = new EventEmitter();
  public replayStopped = new EventEmitter();

  private playbackInterval: any;

  public constructor() {
    //TODO: Get the settings from the localstorage!
    const speedMultiplier = localStorage.getItem(this.settings.speedMultiplierKey);
    if (speedMultiplier) this.settings.speedMultiplier.set(+speedMultiplier);

    const enableSmoothPlayback = localStorage.getItem(this.settings.enableSmoothPlaybackKey);
    if (enableSmoothPlayback) this.settings.enableSmoothPlayback.set(enableSmoothPlayback === '1');

    const followVessel = localStorage.getItem(this.settings.followVesselKey);
    if (followVessel) this.settings.followVessel.set(followVessel === '1');

    const showTooltipSource = localStorage.getItem(this.settings.showTooltipSourceKey);
    if (showTooltipSource) this.settings.showTooltipSource.set(showTooltipSource === '1');

    const showTooltipTarget = localStorage.getItem(this.settings.showTooltipTargetKey);
    if (showTooltipTarget) this.settings.showTooltipTarget.set(showTooltipTarget === '1');

    const showNearbyVessels = localStorage.getItem(this.settings.showNearbyVesselsKey);
    if (showNearbyVessels) this.settings.showNearbyVessels.set(showNearbyVessels === '1');

    effect(() => {
      localStorage.setItem(this.settings.speedMultiplierKey, '' + this.settings.speedMultiplier());
    });
    effect(() => {
      localStorage.setItem(this.settings.enableSmoothPlaybackKey, this.settings.enableSmoothPlayback() ? '1' : '0');
    });
    effect(() => {
      localStorage.setItem(this.settings.followVesselKey, this.settings.followVessel() ? '1' : '0');
    });
    effect(() => {
      localStorage.setItem(this.settings.showTooltipSourceKey, this.settings.showTooltipSource() ? '1' : '0');
    });
    effect(() => {
      localStorage.setItem(this.settings.showTooltipTargetKey, this.settings.showTooltipTarget() ? '1' : '0');
    });
    effect(() => {
      localStorage.setItem(this.settings.showNearbyVesselsKey, this.settings.showNearbyVessels() ? '1' : '0');
    });

    effect(
      () => {
        this.getNearbyVessels(this.vessels());
      },
      { allowSignalWrites: true }
    );
  }

  public setVessels(vessels: VesselToFollow[]): void {
    this.vessels.set(vessels);
    this.setupPlayback();
  }

  private setupPlayback() {
    const { minTime, maxTime } = this.getGlobalTimeBounds();
    this.minTime = minTime;
    this.maxTime = maxTime;

    //Will defer the value update until the angulars change detection cycle completes --> If you remove this, an error will be shown in the logs
    setTimeout(() => {
      this.currentTime.set(minTime);
    });
  }

  private getGlobalTimeBounds() {
    let minTime = Infinity;
    let maxTime = -Infinity;

    this.vessels().forEach((vessel) => {
      vessel.histories.forEach((point) => {
        const time = new Date(point.time!).getTime();
        if (time < minTime) minTime = time;
        if (time > maxTime) maxTime = time;
      });
    });

    return { minTime, maxTime };
  }

  // public ngOnDestroy(): void {
  //   //TOOD: Implement!
  // }

  public playPausePlayback() {
    if (this.isPlaying()) {
      this.pausePlayback();
      this.replayPaused.emit();
    } else {
      this.startPlayback();
    }
  }

  private pausePlayback() {
    clearInterval(this.playbackInterval);
    this.isPlaying.set(false);
  }

  public stopPlayback() {
    this.pausePlayback();
    this.currentTime.set(this.minTime);

    this.replayStopped.emit();
  }

  private startPlayback() {
    this.isPlaying.set(true);

    this.replayStarted.emit();

    this.playbackInterval = setInterval(() => {
      if (this.currentTime() > this.maxTime) {
        this.stopPlayback();
        return;
      }

      this.currentTime.update((currentTime) => currentTime + this.timeIncrement * this.settings.speedMultiplier());
    }, this.playbackSpeed);
  }

  private getNearbyVessels(vessels: VesselToFollow[]) {
    if (vessels.length <= 0) {
      this.vesselsNearby.set([]);
      return;
    }
    this.isLoadingSecondaryVessels.set(true);
    const { minTime, maxTime } = this.getGlobalTimeBounds();
    const from = new Date(minTime);
    from.setMinutes(from.getMinutes() - 5);
    const until = new Date(maxTime);

    const flatPointsInHistory = vessels.flatMap((f) => f.histories.flatMap((h) => new LatLng(h.lat!, h.lng!)));

    const boundsVesselHistory = this.getBoundingBoxWithBuffer(flatPointsInHistory);

    if (boundsVesselHistory == undefined) {
      this.isLoadingSecondaryVessels.set(false);
      return;
    }

    this.historyClient
      .getHistory(from, until, AggregateLevel.SECOND_10, undefined, boundsVesselHistory, undefined, undefined)
      .pipe(
        map((histories: GetObjectHistoryResponse[]) =>
          histories.filter((history) => !vessels.some((vessel) => vessel.vesselInfo.mmsi === history.oid))
        ),
        map((histories: GetObjectHistoryResponse[]) => this.groupHistories(histories)),
        tap((vesselInfo) => {
          this.vesselsNearby.set(vesselInfo);
        }),
        catchError(() => of([])),
        finalize(() => this.isLoadingSecondaryVessels.set(false))
      )
      .subscribe();
  }

  private groupHistories(data: GetObjectHistoryResponse[]) {
    const groupedHistories = data.reduce((groups, item) => {
      // Create a composite key. You might want to handle undefined values.
      const key = `${item.oid ?? 'null'}-${item.ship_type ?? 'null'}-${item.name ?? 'null'}-${item.call_sign ?? 'null'}-${item.imo_number ?? 'null'}`;

      // Initialize the group if it doesn't exist yet.
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(item);
      return groups;
    }, {} as Record<string, GetObjectHistoryResponse[]>);

    return Object.keys(groupedHistories).map((key) => {
      const groupHistories = groupedHistories[key];

      // Extract the vessel info from the first item in the group.
      const firstHistory = groupHistories[0];
      const vesselInfo: VesselInfo = {
        mmsi: firstHistory.oid,
        meta: {
          shipType: firstHistory.ship_type,
          imoNumber: firstHistory.imo_number,
          dimToBow: firstHistory.bow,
          dimToPort: firstHistory.port,
          dimToStarboard: firstHistory.starboard,
          dimToStern: firstHistory.stern,
        } as VesselMeta,
        name: firstHistory.name,
        width: firstHistory.width,
        length: firstHistory.length,
      };

      // Assuming your histories are not pre-sorted, you might want to determine
      // the start and end based on the earliest and latest times.
      // (The VesselToFollow constructor will also filter, map, and sort the histories.)
      const start = groupHistories[0].time;
      const end = groupHistories[groupHistories.length - 1].time;

      return new VesselToFollow(vesselInfo, false, groupHistories, start, end);
    });
  }

  private getBoundingBoxWithBuffer(points: LatLng[]) {
    if (points.length === 0) {
      console.error('No points provided');
      return;
    }

    // Convert the points into GeoJSON Features
    const features = points.map((point) => turf.point([point.lng, point.lat]));
    const collection = turf.featureCollection(features);

    // Calculate the bounding box for the original points
    // bbox returns an array in the form: [minLng, minLat, maxLng, maxLat]
    const originalBbox = turf.bbox(collection);

    // Define the top left and bottom right coordinates
    const topLeft = { lng: originalBbox[0], lat: originalBbox[3] }; // [minLng, maxLat]
    const bottomRight = { lng: originalBbox[2], lat: originalBbox[1] }; // [maxLng, minLat]

    return `${bottomRight.lat},${topLeft.lng},${topLeft.lat},${bottomRight.lng}`;
  }
}
