import { Directive, inject, TemplateRef, ViewContainerRef, signal, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AtlantisNgxAuthService } from '@dotocean/atlantis-ngx-auth';
import { canViewFeature, FeatureFlagName } from './feature-flag.service';

@Directive({
  selector: '[featureFlag]',
})
export class FeatureFlagDirective implements OnInit, OnChanges {
  // Accept a feature flag key as input.
  @Input('featureFlag') featureFlag!: FeatureFlagName;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private authService: AtlantisNgxAuthService) {}

  ngOnInit(): void {
    this.updateView();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // If the feature flag input changes, update the view accordingly.
    if (changes['featureFlag']) {
      this.updateView();
    }
  }

  private updateView() {
    // Retrieve the current session from the AuthService.
    const identity = this.authService.identity;

    // If a session exists and the feature flag check passes, render the element.
    if (identity && canViewFeature(this.featureFlag, identity)) {
      // If the view hasn't been rendered yet, create it.
      if (this.viewContainer.length === 0) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    } else {
      // Otherwise, clear the view (i.e., hide the element).
      this.viewContainer.clear();
    }
  }
}
