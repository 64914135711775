import { Identity } from '@dotocean/atlantis-ngx-auth';

export type FeatureFlagName = keyof typeof FEATURE_FLAGS;

//https://www.youtube.com/watch?v=VBCYqp8l3Lc
//https://github.com/WebDevSimplified/feature-flags-sample-code/blob/main/src/lib/featureFlags.ts

type FeatureFlagRule = {
  groupIds?: number[];
} & { groupIds: number[] };

//Temporary hard coded --> Should probably come from a service, but we do not possess this power ATM :)
export const FEATURE_FLAGS = {
  ALLOW_TOWAGE: [{ groupIds: [65] }],
  ALLOW_ALERTS: true,
} as const satisfies Record<string, FeatureFlagRule[] | boolean>;

export function canViewFeature(featureName: FeatureFlagName, identity: Identity) {
  const rules = FEATURE_FLAGS[featureName];
  if (typeof rules === 'boolean') return rules;
  return rules.some((rule) => checkRule(rule, featureName, identity));
}

function checkRule({ groupIds }: FeatureFlagRule, featureName: FeatureFlagName, identity: Identity) {
  return userHasGroupAccess(groupIds, identity.Application.Groups);
  //&& userHasValidRole(userRoles, user.role)
}

function userHasGroupAccess(allowedGroupIds: number[] | undefined, groupIdsFromUser: number[] | undefined) {
  return !allowedGroupIds || (groupIdsFromUser && allowedGroupIds.some((a) => groupIdsFromUser.includes(a)));
}

// function userHasValidRole(allowedRoles: UserRole[] | undefined, userRole: UserRole) {
//   return allowedRoles == null || allowedRoles.includes(userRole);
// }
