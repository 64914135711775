import { GetObjectsResponse, SourceTypeColor } from '@dotocean/virtualworld-ngx-services';
import moment from 'moment';
import { AssetList, ResData } from '../services/apis/cloud.service';

export class MapTrackerData {
  name: string | undefined;
  mmsi: number;
  imoNumber: number | undefined;
  eniNumber: number | undefined;
  lat: number;
  lng: number;
  course: number;
  speed: number | undefined;
  trueHeading: number | undefined;
  heading: number | undefined;
  time: moment.Moment;
  color: string;
  obj: GetObjectsResponse | undefined;
  asset: AssetList | undefined;
  sourceType: SourceTypeColor | undefined;

  unknownVesselLocation = false;

  constructor(
    name: string | undefined,
    mmsi: number,
    imoNumber: number | undefined,
    eniNumber: number | undefined,
    lat: number,
    lng: number,
    course: number,
    speed: number | undefined,
    trueHeading: number | undefined,
    heading: number | undefined,
    time: moment.Moment,
    color: string,
    obj: GetObjectsResponse | undefined,
    asset: AssetList | undefined,
    sourceType: SourceTypeColor | undefined,
    unknownVesselLocation = false
  ) {
    this.name = name;
    this.imoNumber =  Number.isNaN(imoNumber) ? undefined : imoNumber;
    this.mmsi = mmsi;
    this.lat = lat;
    this.lng = lng;
    this.course = course;
    this.speed = speed;
    this.trueHeading = trueHeading;
    this.heading = heading;
    this.time = time;
    this.color = color;
    this.obj = obj;
    this.asset = asset;
    this.sourceType = sourceType;
    this.unknownVesselLocation = unknownVesselLocation;
  }

  public static fromUnknownVesselData(vesselRes: ResData, sourceType: SourceTypeColor | undefined) {
    return new MapTrackerData(
      vesselRes.name,
      +vesselRes.id!,
      undefined,
      undefined,
      +vesselRes.lat!,
      +vesselRes.lng!,
      0,
      0,
      0,
      0,
      moment(),
      '#78D0E7',
      undefined,
      undefined,
      sourceType,
      true
    );
  }

  public static fromObjectNavigation(
    obj: GetObjectsResponse,
    color: string,
    asset: AssetList | undefined,
    sourceType: SourceTypeColor | undefined,
    unknownVesselLocation = false
  ): MapTrackerData {
    const speed = obj.s ? Math.round(obj.s * 100) / 100 : 0;
    const course = obj.c ? Math.round(obj.c * 100) / 100 : 0;
    const trueHeading = obj.t_h ? Math.round(obj.t_h * 100) / 100 : 0;
    const heading = obj.h ? Math.round(obj.h * 100) / 100 : undefined;

    const imoNumber = obj.meta ? +obj.meta['IMO_NUMBER'] : undefined;
    const eniNumber = obj.meta ? +obj.meta['ENI_NUMBER'] : undefined;

    return new MapTrackerData(
      obj.name,
      obj.oid!,
      imoNumber === 0 ? undefined : imoNumber,
      eniNumber === 0 ? undefined : eniNumber,
      obj.lat!,
      obj.lng!,
      course,
      speed,
      trueHeading,
      heading,
      moment.unix(obj.time! / 1000).local(),
      color,
      obj,
      asset,
      sourceType,
      unknownVesselLocation
    );
  }
}
