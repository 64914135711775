<div class="event-player prevent-select">
  <app-button-group class="event-player-options">
    <button
      class="btn btn-primary p-1"
      (click)="aisReplayService.settings.showNearbyVessels.set(!aisReplayService.settings.showNearbyVessels())"
      [class.button-group--item-active]="aisReplayService.settings.showNearbyVessels()"
      [class.button-group--item-isLoading]="aisReplayService.isLoadingSecondaryVessels()"
      hoverState
      (isHovering)="nearbyIconHovering = $event"
      ngbTooltip="Nearby vessels"
      placement="right"
      [openDelay]="300"
      container="body"
      [disabled]="!aisReplayService.settings.showNearbyVessels() && aisReplayService.isLoadingSecondaryVessels()"
    >
      @if(aisReplayService.isLoadingSecondaryVessels()){
      <app-loading-dot
        class="loading-popup--icon"
        [isSelected]="aisReplayService.settings.showNearbyVessels()"
        [themeReverseOnlyLightMode]="true"
      ></app-loading-dot>
      }@else {
      <img 
        themedImage
        srcLight="icons/vessels-nearby-dark.svg"
        srcDark="icons/vessels-nearby-light.svg"
        [isHovering]="nearbyIconHovering"
        [themeReverse]="aisReplayService.settings.showNearbyVessels()"
        [themeReverseOnlyLightMode]="true"
        class="bi pe-none"
        height="30"
        width="30"
        alt=""
      />
      }
    </button>
    <button
      class="btn btn-primary"
      ngbTooltip="Smooth playback"
      placement="right"
      [openDelay]="300"
      container="body"
      (click)="aisReplayService.settings.enableSmoothPlayback.set(!aisReplayService.settings.enableSmoothPlayback())"
      [class.button-group--item-active]="aisReplayService.settings.enableSmoothPlayback()"
    >
      <i class="fa-solid fa-water"></i>
    </button>
    <button
      class="btn btn-primary"
      ngbTooltip="Pin to vessel"
      placement="right"
      [openDelay]="300"
      container="body"
      (click)="aisReplayService.settings.followVessel.set(!aisReplayService.settings.followVessel())"
      [class.button-group--item-active]="aisReplayService.settings.followVessel()"
    >
      <i class="fa-solid fa-location-crosshairs"></i>
    </button>
    <button
      class="btn btn-primary"
      ngbTooltip="Show tooltip target"
      placement="right"
      [openDelay]="300"
      container="body"
      (click)="aisReplayService.settings.showTooltipTarget.set(!aisReplayService.settings.showTooltipTarget())"
      [class.button-group--item-active]="aisReplayService.settings.showTooltipTarget()"
    >
      <i [class.fa-bullseye]="aisReplayService.isFromEvent" [class.fa-tag]="!aisReplayService.isFromEvent" class="fa-solid"></i>
    </button>

    @if(aisReplayService.isFromEvent){
    <button
      class="btn btn-primary"
      ngbTooltip="Show tooltip source"
      placement="right"
      [openDelay]="300"
      container="body"
      (click)="aisReplayService.settings.showTooltipSource.set(!aisReplayService.settings.showTooltipSource())"
      [class.button-group--item-active]="aisReplayService.settings.showTooltipSource()"
    >
      <i class="fa-brands fa-sourcetree"></i>
    </button>
    }
  </app-button-group>
  <!-- <div class="event-player-events event-player--buttons"> -->
  <app-button-group class="event-player-events" direction="horizontal">
    <button class="btn btn-primary" (click)="aisReplayService.stopPlayback()" [disabled]="!aisReplayService.isPlaying()">
      <i class="fa-solid fa-backward-step"></i>
    </button>
    <button class="btn btn-primary" (click)="aisReplayService.playPausePlayback()">
      <i [class.fa-play]="!aisReplayService.isPlaying()" [class.fa-pause]="aisReplayService.isPlaying()" class="fa-solid"></i>
    </button>

    <div class="btn-group button-group--items" ngbDropdown placement="top-start">
      <button type="button" class="btn btn-primary button-group--item" ngbDropdownToggle>x{{ aisReplayService.settings.speedMultiplier() }}</button>
      <div class="dropdown-menu" ngbDropdownMenu>
        @for (item of aisReplayService.speedMultiplierProfiles; track $index) {
        <button
          [class.button-group--item-active]="aisReplayService.settings.speedMultiplier() === item"
          ngbDropdownItem
          (click)="aisReplayService.settings.speedMultiplier.set(item)"
        >
          x{{ item }}
        </button>
        }
      </div>
    </div>
  </app-button-group>
  <!-- <div class="btn-group me-3">
      <button class="btn btn-primary event-player--button" (click)="aisReplayService.stopPlayback()" [disabled]="!aisReplayService.isPlaying()">
        <i class="fa-solid fa-backward-step"></i>
      </button>
      <button class="btn btn-primary event-player--button" (click)="aisReplayService.playPausePlayback()">
        <i [class.fa-play]="!aisReplayService.isPlaying()" [class.fa-pause]="aisReplayService.isPlaying()" class="fa-solid"></i>
      </button>

      <div class="btn-group" ngbDropdown placement="top-end">
        <button type="button" class="btn btn-primary event-player--button" ngbDropdownToggle>
          x{{ aisReplayService.settings.speedMultiplier() }}
        </button>
        <div class="dropdown-menu" ngbDropdownMenu>
          @for (item of aisReplayService.speedMultiplierProfiles; track $index) {
          <button ngbDropdownItem (click)="aisReplayService.settings.speedMultiplier.set(item)">x{{ item }}</button>
          }
        </div>
      </div>
    </div>
  </div> -->

  <!-- @if(showSlider){
  <div class="event-player--items event-player--range">
    <div class="event-player--item">
      <input
        type="range"
        [min]="aisReplayService.minTime"
        [max]="aisReplayService.maxTime"
        [(ngModel)]="aisReplayService.currentTime"
        [ngModel]="aisReplayService.currentTime()"
        (ngModelChange)="aisReplayService.currentTime.set($event)"
      />
    </div>
    <div class="event-player--item event-player--item-time">
      Time: @if(aisReplayService.currentTime() !== inifinty){
      {{ aisReplayService.currentTime() | date : "dd/MM/yy HH:mm:ss" }}
      }
    </div>
  </div>
  } -->
</div>
