import { GetSourcesResponse } from '@dotocean/virtualworld-ngx-services';
import { Region, RegionSmall } from '../services/apis/cloud.service';
import L, { CircleMarkerOptions, PathOptions } from 'leaflet';

export function regionIsPolygon(region: RegionSmall | Region) {
  return region.polygons && region.polygons.length > 0;
}

export function getRegionLeafletObject(region: RegionSmall | Region, outlineOnly = false) {
  const style = outlineOnly
    ? ({ color: '#f03', fill: false, opacity: 0.5, stroke: true, pane: 'overlayPane', interactive: false } as PathOptions)
    : ({ color: 'red', fillColor: '#f03', fillOpacity: 0.5, stroke: false, pane: 'overlayPane', interactive: false } as PathOptions);

  if (regionIsPolygon(region)) {
    return L.polygon(
      region.polygons!.map((p) => new L.LatLng(p.latitude!, p.longitude!)),
      style
    );
  }

  if (!region.latitude || !region.longitude) return undefined;

  // Draw a circle (radius) on the map
  return L.circle([region.latitude, region.longitude], { ...style, radius: region.radius! * 1000 });
}

export function getSourcesLeafletObject(source: GetSourcesResponse) {
  if (!source.lat || !source.lng || !source.online) return undefined;

  let customIcon = L.icon({
    iconUrl: 'icons/radar-dark.svg',
    iconSize: [50, 50],
  });

  const layerGroup = new L.LayerGroup();
  L.marker([source.lat, source.lng], { icon: customIcon })
    .bindTooltip('Radar ID: ' + source.sid)
    .addTo(layerGroup);

  if (!source.meta) return layerGroup;
  if (source.meta!['RADAR_POLYGON']) {
    L.geoJSON(source.meta!['RADAR_POLYGON'] as any, { style: { color: 'green' } }).addTo(layerGroup);
    return layerGroup;
  }

  if (source.meta!['RADAR_RANGE']) {
    L.circle([source.lat, source.lng], {
      color: source.online ? '#00AAD4' : '#e1234a',
      radius: +source.meta!['RADAR_RANGE'],
      stroke: false,
      fillOpacity: 0.1,
    } as CircleMarkerOptions).addTo(layerGroup);
  }

  return layerGroup;
}
