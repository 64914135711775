import { Injectable, OnDestroy, signal, WritableSignal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import L, { LeafletMouseEvent, MarkerClusterGroup } from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.pattern';
import { MapPopupService } from './map-popup.service';
import { VesselToFollow } from '../app/events/events-detail/vessel-tofollow.class';

export interface MapBounds {
  latSW: number;
  lngSW: number;
  latNE: number;
  lngNE: number;
}

export interface MapBoundsMinMax {
  minLat: number;
  maxLat: number;
  minLng: number;
  maxLng: number;
}

export interface MapSettings {
  addObjectOverlay?: boolean;
  showLayerControl?: boolean;
  showScale?: boolean;
  showMeasurementTool?: boolean;
}

@Injectable()
export class MapService implements OnDestroy {
  public stripePattern = new L.StripePattern({ angle: 45, color: '#E1234A', opacity: 0.3 });
  public mapBounds?: MapBoundsMinMax;
  public isServiceDestroyed = false;
  public mapLoaded$ = new BehaviorSubject<boolean>(false);
  public mapLoaded = signal(false);
  public Map?: L.Map;

  public mousePosition: WritableSignal<L.LatLng | undefined> = signal(undefined);
  private clusteredMarkersGroupedByAlertSource: Record<number, MarkerClusterGroup> = {};

  constructor(private readonly mapPopupService: MapPopupService) {}

  private overrideDefaultMapSettings(mapSettings: MapSettings) {
    mapSettings.addObjectOverlay = mapSettings.addObjectOverlay === undefined ? true : mapSettings.addObjectOverlay;
    mapSettings.showLayerControl = mapSettings.showLayerControl === undefined ? true : mapSettings.showLayerControl;
    mapSettings.showScale = mapSettings.showScale === undefined ? true : mapSettings.showScale;
    mapSettings.showMeasurementTool = mapSettings.showMeasurementTool === undefined ? true : mapSettings.showMeasurementTool;
  }

  public async initMap(mapSettings: MapSettings, mapName = 'map') {
    this.overrideDefaultMapSettings(mapSettings);
    this.isServiceDestroyed = false;
    if (this.isServiceDestroyed) return;

    this.Map = L.map(mapName, {
      zoomControl: false,
      attributionControl: false,
    });
    this.Map.whenReady(() => {
      setTimeout(() => {
        this.Map?.invalidateSize();

        this.setBounds();

        this.stripePattern.addTo(this.Map!);

        // Set the z-index lower than other panes (default overlayPane has z-index 400)
      this.addPane('vesselNearbyPane', '401');
        this.addPane('vesselPane', '402');
        this.addPane('overlayPane', '403');

        this.Map?.on('mousemove', (event: LeafletMouseEvent) => {
          this.mousePosition.set(event.latlng);
        });

        //This is to make sure the bounds are always set correctly
        this.subscribeTo_MoveEnd(undefined);
        this.mapLoaded$.next(true);
        this.mapLoaded.set(true);
      }, 100);
    });

    this.getOpenStreetMapLayer().addTo(this.Map);
    if (mapSettings.showScale) L.control.scale({ position: 'bottomright', imperial: false }).addTo(this.Map);

    this.setViewToDotOcean();
  }

  public addPane(name: string, zIndex: string) {
    if (!this.Map?.getPane(name)) {
      this.Map!.createPane(name);
      this.Map!.getPane(name)!.style.zIndex = zIndex;
    }
  }

  public centerViewToCoordinate(coordinates: [number, number]) {
    this.Map?.setView(coordinates, this.Map?.getZoom(), { animate: true });
  }

  public addToClusteredMarkers(owned: boolean | undefined, marker: L.Marker, layerGroup: L.LayerGroup<any>) {
    const numberFromSource = owned ? 1 : 0;
    let clusteredMarkers = this.clusteredMarkersGroupedByAlertSource[numberFromSource];
    if (!clusteredMarkers) {
      clusteredMarkers = L.markerClusterGroup({
        iconCreateFunction: function (cluster) {
          return L.divIcon({
            className: 'markerVessel' + (owned ? '' : 'markerVessel-favorite'),
            iconAnchor: [23.5, 60],
            html: `<div class="markerVessel markerVessel-clustered ${
              owned ? '' : 'markerVessel-clustered-favorite'
            }"> <div class="markerVessel-content"> ${cluster.getChildCount()} </div>`,
          });
        },
      });
      layerGroup.addLayer(clusteredMarkers);
      this.clusteredMarkersGroupedByAlertSource[numberFromSource] = clusteredMarkers;
    }

    clusteredMarkers.addLayer(marker);
  }

  public removeFromClusteredMarkers(marker: L.Marker) {
    Object.keys(this.clusteredMarkersGroupedByAlertSource).forEach((key: any) => {
      if (this.clusteredMarkersGroupedByAlertSource[key].hasLayer(marker)) {
        this.clusteredMarkersGroupedByAlertSource[key].removeLayer(marker);
      }
    });
  }

  private getOpenStreetMapLayer() {
    return L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
    });
  }

  public getOpenSeaMapLayer() {
    return L.tileLayer('https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png', {
      bounds: [
        [-90, -180],
        [90, 180],
      ],
      noWrap: true,
      maxZoom: 22,
    });
  }

  public subscribeTo_Move(fn: L.LeafletEventHandlerFn) {
    this.Map?.on('move', fn);
  }

  public subscribeTo_Click(fn: L.LeafletMouseEventHandlerFn) {
    this.Map?.on('click', fn);
  }

  public subscribeTo_MoveEnd(fn: L.LeafletEventHandlerFn | undefined) {
    this.Map?.on(
      'moveend',
      fn ??
        (async () => {
          await this.setBounds();
        })
    );
  }

  public setViewToDotOcean() {
    if (!this.Map) return;
    this.Map.setView([51.20151, 3.196264], 16);
  }

  public setDefaultView() {
    if (!this.Map) return;
    this.Map.setView([51.20151, 3.196264], 1);
  }

  public removeLayer(layer: L.Layer) {
    if (this.isServiceDestroyed) return;
    this.Map?.removeLayer(layer);
  }

  public async setBounds() {
    if (!this.Map) return;

    const bounds = this.Map.getBounds();
    this.mapBounds = {
      minLat: bounds.getSouthWest().lat,
      maxLat: bounds.getNorthEast().lat,
      minLng: bounds.getSouthWest().lng,
      maxLng: bounds.getNorthEast().lng,
    };
  }

  public getBounds(): MapBounds {
    return {
      latSW: this.mapBounds?.minLat ?? 0,
      lngSW: this.mapBounds?.minLng ?? 0,
      latNE: this.mapBounds?.maxLat ?? 0,
      lngNE: this.mapBounds?.maxLng ?? 0,
    };
  }

  public Dispose() {
    this.isServiceDestroyed = true;
    if (this.Map) {
      this.Map.remove();
      this.Map = undefined;
    }
  }

  public ngOnDestroy(): void {
    this.Dispose();
  }

  public fitVessels(vessels: VesselToFollow[]) {
    if (vessels.length === 0 || !this.Map) return;
    const allHistories = vessels.flatMap((vessel) => vessel.histories);
    if (allHistories.length === 0) return;

    this.Map.setView([allHistories[0].lat!, allHistories[0].lng!], this.Map.getZoom());
    const bounds = L.latLngBounds(allHistories.map((coord) => [coord.lat!, coord.lng!]));
    // Adjust the map view to fit the bounds
    this.Map.fitBounds(bounds, { padding: [15, 15], maxZoom: 16 });
  }

  public placeTooltipAtCenter(layer: L.Layer, layerGroup: L.LayerGroup<any> | undefined, tooltipText: string, classname: string | undefined) {
    if ((layer instanceof L.Polygon || layer instanceof L.Polyline) && layerGroup) {
      // 1) Compute topCenter
      //const polyCenter = layer.getCenter();
      const bounds = layer.getBounds();
      const nw = bounds.getNorthWest();
      const se = bounds.getSouthEast();
      const center = L.latLng((nw.lat + se.lat) / 2, (nw.lng + se.lng) / 2);

      // layer.bindTooltip(tooltipText, { permanent: true, direction: 'top', offset: [0, 15], className: classname });
      // layer.openTooltip(center);

      // 2) Create a "free-floating" tooltip
      const myTooltip = L.tooltip({
        //permanent: true,
        direction: 'top',
        offset: [0, 0],
        className: classname,
        sticky: true,
      })
        .setLatLng(center)
        .setContent(tooltipText);
      //.addTo(layerGroup);

      layer.bindTooltip(myTooltip);
      //layer.openTooltip(center);
    }
  }
}
