import { Injectable } from '@angular/core';
import { AtlantisNgxAuthService } from '@dotocean/atlantis-ngx-auth';
import { GetObjectsResponse, GetSourcesResponse, ObjectClient, SourceClient, SourceType, SourceTypeColor } from '@dotocean/virtualworld-ngx-services';
import { Observable, shareReplay, startWith, Subject, switchMap } from 'rxjs';

@Injectable()
export class ObjectsCacheApiService {
  public sourceTypeColors: SourceTypeColor[];
  public SourceTypeColor$: Observable<SourceTypeColor[]>;

  private refreshSourcesSubject = new Subject<void>();
  public sources$: Observable<GetSourcesResponse[]> = this.refreshSourcesSubject.pipe(
    // Emit an initial value so the API call is made on component init
    startWith(void 0),
    // Each time the subject emits, switch to a new API call observable
    switchMap(() => this.sourceClient.getSources(undefined)),
    // Cache the latest emitted value for new subscribers
    shareReplay(1)
  );
  constructor(objectClient: ObjectClient, authService: AtlantisNgxAuthService, private sourceClient: SourceClient) {
    this.SourceTypeColor$ = authService.identity$.pipe(
      switchMap((i) => objectClient.getTypesColors(i?.Application.Groups[0] ?? 1, undefined).pipe(shareReplay(1)))
    );

    this.SourceTypeColor$.subscribe((sourceTypeColor) => (this.sourceTypeColors = sourceTypeColor));
  }

  public refreshSources(): void {
    this.refreshSourcesSubject.next();
  }

  public getShipTypeToDisplay(object: GetObjectsResponse) {
    return this.getShiptypeFromShipType(object.type!, object.meta ? +object.meta['SHIP_TYPE'] : undefined);
  }

  public getShiptypeFromShipType(sourceType: SourceType, shipType: number | undefined) {
    if (!this.sourceTypeColors) return undefined;
    const filtered = this.sourceTypeColors.filter((s) => s.sourceType === sourceType);

    let foundStc = filtered.find((f) => f.shipType == shipType);

    if (!foundStc && filtered.length > 0) {
      foundStc = filtered[0];
      if (foundStc) {
        foundStc.shipType = shipType;
      }
    }

    return foundStc;
  }
}
